var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
          [
            _c(
              "q-form",
              { ref: "editForm" },
              [
                _c(
                  "c-card",
                  {
                    staticClass: "cardClassDetailForm",
                    attrs: { title: "인허가 요청정보" },
                  },
                  [
                    _c(
                      "template",
                      { slot: "card-button" },
                      [
                        _c(
                          "q-btn-group",
                          { attrs: { outline: "" } },
                          [
                            _vm.editable && _vm.isStepReq1 && _vm.isRequestUser
                              ? _c("c-btn", {
                                  attrs: {
                                    url: _vm.saveUrl,
                                    isSubmit: _vm.isSave,
                                    param: _vm.data,
                                    mappingType: _vm.mappingType,
                                    label: "저장",
                                    icon: "save",
                                  },
                                  on: {
                                    beforeAction: _vm.saveData,
                                    btnCallback: _vm.saveCallback,
                                  },
                                })
                              : _vm._e(),
                            _vm.editable &&
                            _vm.param.limLicenseRequestId &&
                            _vm.isStepReq &&
                            _vm.isRequestUser
                              ? _c("c-btn", {
                                  attrs: { label: "요청완료", icon: "save" },
                                  on: { btnClicked: _vm.saveEnd },
                                })
                              : _vm._e(),
                            _vm.editable &&
                            _vm.param.limLicenseRequestId &&
                            _vm.isDelete &&
                            _vm.isRequestUser
                              ? _c("c-btn", {
                                  attrs: {
                                    label: "삭제",
                                    icon: "delete_forever",
                                  },
                                  on: { btnClicked: _vm.deleteData },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("template", { slot: "card-detail" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9",
                        },
                        [
                          _c("c-text", {
                            attrs: {
                              required: true,
                              editable: _vm.editable,
                              label: "인허가 요청명",
                              name: "licenseRequestName",
                            },
                            model: {
                              value: _vm.data.licenseRequestName,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "licenseRequestName", $$v)
                              },
                              expression: "data.licenseRequestName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                        },
                        [
                          _c("c-plant", {
                            attrs: {
                              required: true,
                              editable: _vm.editable,
                              type: "edit",
                              name: "plantCd",
                            },
                            model: {
                              value: _vm.data.plantCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "plantCd", $$v)
                              },
                              expression: "data.plantCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                        },
                        [
                          _c("c-field", {
                            attrs: {
                              editable: _vm.editable,
                              disabled: true,
                              data: _vm.data,
                              deptValue: "requestDeptCd",
                              type: "dept_user",
                              label: "요청부서 & 요청자",
                              name: "requestUserId",
                            },
                            model: {
                              value: _vm.data.requestUserId,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "requestUserId", $$v)
                              },
                              expression: "data.requestUserId",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                        },
                        [
                          _c("c-field", {
                            attrs: {
                              required: true,
                              editable: _vm.editable,
                              data: _vm.data,
                              deptValue: "reviewDeptCd",
                              type: "dept_user",
                              label: "검토부서 & 검토자",
                              name: "reviewUserId",
                            },
                            model: {
                              value: _vm.data.reviewUserId,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "reviewUserId", $$v)
                              },
                              expression: "data.reviewUserId",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                        },
                        [
                          _c("c-datepicker", {
                            attrs: {
                              required: true,
                              editable: _vm.editable,
                              label: "인허가 완료 요청일",
                              name: "completeRequestDate",
                            },
                            model: {
                              value: _vm.data.completeRequestDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "completeRequestDate", $$v)
                              },
                              expression: "data.completeRequestDate",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                        },
                        [
                          _c("c-text", {
                            attrs: {
                              afterIcon: _vm.editable
                                ? [
                                    {
                                      name: "search",
                                      click: true,
                                      callbackName: "searchMoc",
                                      color: "teal",
                                    },
                                    {
                                      name: "close",
                                      click: true,
                                      callbackName: "removeMoc",
                                      color: "red",
                                    },
                                  ]
                                : null,
                              editable: _vm.editable,
                              disabled: true,
                              label: "MOC번호",
                              name: "sopMocId",
                            },
                            on: {
                              searchMoc: _vm.searchMoc,
                              removeMoc: _vm.removeMoc,
                            },
                            model: {
                              value: _vm.data.sopMocId,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "sopMocId", $$v)
                              },
                              expression: "data.sopMocId",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-12" },
                        [
                          _c("c-textarea", {
                            attrs: {
                              label: "요청 내용",
                              editable: _vm.editable,
                              name: "requestContent",
                              rows: 2,
                            },
                            model: {
                              value: _vm.data.requestContent,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "requestContent", $$v)
                              },
                              expression: "data.requestContent",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                        },
                        [
                          _c("c-select", {
                            attrs: {
                              disabled: true,
                              codeGroupCd: "LICENSE_REQUEST_STEP_CD",
                              type: "edit",
                              itemText: "codeName",
                              itemValue: "code",
                              name: "requestStepCd",
                              label: "진행단계",
                            },
                            model: {
                              value: _vm.data.requestStepCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "requestStepCd", $$v)
                              },
                              expression: "data.requestStepCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9",
                        },
                        [
                          _c("c-textarea", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.data.requestStepCd == "RSC0999999",
                                expression:
                                  "data.requestStepCd == 'RSC0999999'",
                              },
                            ],
                            attrs: {
                              label: "기각사유",
                              editable: false,
                              name: "dismissReason",
                              rows: 1,
                            },
                            model: {
                              value: _vm.data.dismissReason,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "dismissReason", $$v)
                              },
                              expression: "data.dismissReason",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm.param.limLicenseRequestId
          ? _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7" },
              [
                _c("c-table", {
                  ref: "table",
                  attrs: {
                    title: "인허가 검토 목록",
                    tableId: "table",
                    columnSetting: false,
                    isFullScreen: false,
                    usePaging: false,
                    columns: _vm.grid.columns,
                    data: _vm.grid.data,
                    gridHeight: "300px",
                  },
                  on: { rowClick: _vm.rowClick },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.param.limLicenseRequestId
          ? _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5" },
              [
                _c(
                  "q-form",
                  { ref: "editForm2" },
                  [
                    _c(
                      "c-card",
                      {
                        staticClass: "cardClassDetailForm",
                        attrs: { title: "인허가 검토 상세" },
                      },
                      [
                        _c(
                          "template",
                          { slot: "card-button" },
                          [
                            _c(
                              "q-btn-group",
                              { attrs: { outline: "" } },
                              [
                                _vm.editable &&
                                _vm.data.requestStepCd == "RSC0000001"
                                  ? _c("c-btn", {
                                      attrs: { label: "신규", icon: "add" },
                                      on: { btnClicked: _vm.addReview },
                                    })
                                  : _vm._e(),
                                _vm.editable &&
                                _vm.saveable &&
                                _vm.data.requestStepCd == "RSC0000001"
                                  ? _c("c-btn", {
                                      attrs: {
                                        url: _vm.saveReviewUrl,
                                        isSubmit: _vm.isReviewSave,
                                        param: _vm.dataReview,
                                        mappingType: _vm.saveReviewType,
                                        label: "저장",
                                        icon: "save",
                                      },
                                      on: {
                                        beforeAction: _vm.saveReview,
                                        btnCallback: _vm.saveReviewCallback,
                                      },
                                    })
                                  : _vm._e(),
                                _vm.editable &&
                                _vm.deleteable &&
                                _vm.data.requestStepCd == "RSC0000001"
                                  ? _c("c-btn", {
                                      attrs: {
                                        label: "삭제",
                                        icon: "delete_forever",
                                      },
                                      on: { btnClicked: _vm.deleteReview },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("template", { slot: "card-detail" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                            },
                            [
                              _c("c-text", {
                                attrs: {
                                  required: true,
                                  editable: false,
                                  label: "관련법규",
                                  name: "relatedLawsName",
                                },
                                model: {
                                  value: _vm.dataReview.relatedLawsName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.dataReview,
                                      "relatedLawsName",
                                      $$v
                                    )
                                  },
                                  expression: "dataReview.relatedLawsName",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                            },
                            [
                              _c("c-text", {
                                attrs: {
                                  required: true,
                                  afterIcon: _vm.editable
                                    ? [
                                        {
                                          name: "search",
                                          click: true,
                                          callbackName: "searchLicenseKindId",
                                          color: "teal",
                                        },
                                        {
                                          name: "close",
                                          click: true,
                                          callbackName: "removeLicenseKindId",
                                          color: "red",
                                        },
                                      ]
                                    : null,
                                  editable: _vm.editable,
                                  readonly: true,
                                  label: "인허가종류",
                                  name: "limLicenseKindName",
                                },
                                on: {
                                  searchLicenseKindId: _vm.searchLicenseKindId,
                                  removeLicenseKindId: _vm.removeLicenseKindId,
                                },
                                model: {
                                  value: _vm.dataReview.limLicenseKindName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.dataReview,
                                      "limLicenseKindName",
                                      $$v
                                    )
                                  },
                                  expression: "dataReview.limLicenseKindName",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                            },
                            [
                              _c("c-field", {
                                attrs: {
                                  required: true,
                                  editable: _vm.editable,
                                  data: _vm.dataReview,
                                  deptValue: "reviewDeptCd",
                                  type: "dept_user",
                                  label: "검토담당부서 & 검토담당자",
                                  name: "reviewUserId",
                                },
                                model: {
                                  value: _vm.dataReview.reviewUserId,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.dataReview,
                                      "reviewUserId",
                                      $$v
                                    )
                                  },
                                  expression: "dataReview.reviewUserId",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                            },
                            [
                              _c("c-select", {
                                attrs: {
                                  disabled: true,
                                  codeGroupCd: "LIM_LICENSE_KIND_STAUS_CD",
                                  type: "edit",
                                  itemText: "codeName",
                                  itemValue: "code",
                                  name: "limLicenseKindStatusCd",
                                  label: "검토상태",
                                },
                                model: {
                                  value: _vm.dataReview.limLicenseKindStatusCd,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.dataReview,
                                      "limLicenseKindStatusCd",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "dataReview.limLicenseKindStatusCd",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                            },
                            [
                              _c("c-radio", {
                                attrs: {
                                  editable: _vm.editable,
                                  comboItems: _vm.useFlagItems,
                                  label: "갱신 여부",
                                  name: "renewalFlag",
                                },
                                model: {
                                  value: _vm.dataReview.renewalFlag,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataReview, "renewalFlag", $$v)
                                  },
                                  expression: "dataReview.renewalFlag",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                            },
                            [
                              _vm.dataReview.renewalFlag == "Y"
                                ? _c("c-text", {
                                    attrs: {
                                      required:
                                        _vm.dataReview.renewalFlag == "Y",
                                      afterIcon: _vm.editable
                                        ? [
                                            {
                                              name: "search",
                                              click: true,
                                              callbackName: "searchLicenseId",
                                              color: "teal",
                                            },
                                            {
                                              name: "close",
                                              click: true,
                                              callbackName: "removeLicenseId",
                                              color: "red",
                                            },
                                          ]
                                        : null,
                                      editable: _vm.editable,
                                      readonly: true,
                                      label: "갱신대상 인허가",
                                      name: "asLicenseSeqName",
                                    },
                                    on: {
                                      searchLicenseId: _vm.searchLicenseId,
                                      removeLicenseId: _vm.removeLicenseId,
                                    },
                                    model: {
                                      value: _vm.dataReview.asLicenseSeqName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dataReview,
                                          "asLicenseSeqName",
                                          $$v
                                        )
                                      },
                                      expression: "dataReview.asLicenseSeqName",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }